import React from "react";
import { InfiniteHits, useHits } from "react-instantsearch";
import { ListingCard } from "../../../components";
import css from "../AlgoliaSearchPage.module.css";

const InfiniteSearch = props => {
    const {
        currentUser,
        categories,
        subCategories,
        onAddOrRemoveToConnected,
        intl
    } = props;

    const { results } = useHits(props);
    const totalResults = results?.nbHits || 0;
    const noResults = intl.formatMessage({ id: "InfiniteSearch.noResultsFound" });

    return (
        <>
            {totalResults > 0 ? <InfiniteHits
                hitComponent={props => (
                    <ListingCard
                        {...props}
                        currentUser={currentUser}
                        categories={categories}
                        subCategories={subCategories}
                        onAddOrRemoveToConnected={onAddOrRemoveToConnected}
                    />
                )}
            /> 
            :
            <div className={css.noResults}>{noResults}</div>}
        </>
    )
};

export default InfiniteSearch;
// const mapStyles = [
//     {
//         elementType: "geometry",
//         stylers: [{ color: "#212121" }]
//     },
//     {
//         elementType: "labels.icon",
//         stylers: [{ visibility: "off" }]
//     },
//     {
//         elementType: "labels.text.fill",
//         stylers: [{ color: "#757575" }]
//     },
//     {
//         elementType: "labels.text.stroke",
//         stylers: [{ color: "#212121" }]
//     },
//     {
//         featureType: "administrative",
//         elementType: "geometry",
//         stylers: [{ color: "#757575" }]
//     },
//     {
//         featureType: "poi",
//         elementType: "labels.text.fill",
//         stylers: [{ color: "#bdbdbd" }]
//     },
//     {
//         featureType: "road",
//         elementType: "geometry",
//         stylers: [{ color: "#383838" }]
//     },
//     {
//         featureType: "road",
//         elementType: "labels.text.fill",
//         stylers: [{ color: "#8a8a8a" }]
//     },
//     {
//         featureType: "road.highway",
//         elementType: "geometry",
//         stylers: [{ color: "#3c3c3c" }]
//     },
//     {
//         featureType: "water",
//         elementType: "geometry",
//         stylers: [{ color: "#0e0e0e" }]
//     },
//     {
//         featureType: "water",
//         elementType: "labels.text.fill",
//         stylers: [{ color: "#3d3d3d" }]
//     }
// ];

export const mapStyles = [
    {
        elementType: "geometry",
        stylers: [{ color: "#fff" }] // Light background
    },
    {
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }] // Hides unnecessary icons
    },
    {
        elementType: "labels.text.fill",
        stylers: [{ color: "#616161" }] // Darker text for contrast
    },
    {
        elementType: "labels.text.stroke",
        stylers: [{ color: "#ffffff" }] // White text stroke
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [{ color: "#bdbdbd" }]
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }] // White roads
    },
    {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [{ color: "#e0e0e0" }] // Slightly shaded roads
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#dadada" }] // Soft gray highways
    },
    {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }]
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }] // Light gray POIs
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#c9e6ff" }] // Light blue water
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#69a3d8" }] // Slightly darker text for water bodies
    }
];

export const mapStylesBright = [
    {
        featureType: "all",
        elementType: "geometry",
        stylers: [
            { saturation: -20 }, // Adjust saturation if needed
            { lightness: 40 } // Increase brightness
        ]
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ lightness: 60 }]
    }
];


import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker, OverlayView, useJsApiLoader } from "@react-google-maps/api";
import { useGeoSearch, useHits } from "react-instantsearch";
import { IconMapMarker, SearchMapInfoCard } from "../../components";
import { createResourceLocatorString } from "../../util/routes";
import routeConfiguration from "../../routeConfiguration";
import { createSlug } from "../../util/urlHelpers";
import css from './AlgoliaSearchPage.module.css';
import { mapStyles, mapStylesBright } from './mapStyle';

const MODAL_BREAKPOINT = 768;
const isWindowDefined = typeof window !== 'undefined';
const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

const mapContainerStyle = {
  width: isMobileLayout ? '100%' : '762px',
  height: isMobileLayout ? '100%' : '710px',
  minWidth: isMobileLayout ? '335px' : '762px',
  minHeight: isMobileLayout ? '850px' : '710px',
};

const defaultCenter = {
  lat: 37.7749, // Default to San Francisco (Change to your location)
  lng: -122.4194,
};



const CustomMarker = ({ lat, lng, onHover, onLeave, onClick }) => {
  return (
    <OverlayView
      position={{ lat, lng }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width, height) => ({ x: -width / 2, y: -height / 2 })}
    >
      <div
        onClick={onClick}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        style={{ cursor: 'pointer' }}
      >
        <IconMapMarker brand="markerone" />
      </div>
    </OverlayView>
  );
};

const AlgoliaMapUpdate = props => {
  const { state, setState, intl, history } = props;

  const { position, refine } = useGeoSearch(props);
  const { hits } = useHits(props);
  const { showMap = false } = state || {};
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [map, setMap] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);

  const [hoveredMarkerPosition, setHoveredMarkerPosition] = useState(null);



  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });
  const onLoad = useCallback(map => {
    const bounds = typeof window !== 'undefined' && new window.google.maps.LatLngBounds(mapCenter);
    map.fitBounds(bounds);
    setMap(map);
  }, []);
  const onUnMount = useCallback(map => setMap(null), []);

  const handleMouseOver = (objectID, lat, lng) => {
    if (!isLoaded || !map) return; // Ensure the API is loaded and map is available

    setHoveredMarker(objectID);
    const scale = Math.pow(2, map.getZoom());
    const nw = new window.google.maps.LatLng(
      map.getBounds().getNorthEast().lat(),
      map.getBounds().getSouthWest().lng()
    );
    const worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
    const worldCoordinate = map.getProjection().fromLatLngToPoint(new window.google.maps.LatLng(lat, lng));
    const pixelOffset = new window.google.maps.Point(
      Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
      Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
    );
    setHoveredMarkerPosition(pixelOffset);
  };

  const customMarkerIcon = isLoaded
    ? {
      url: 'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="41" height="60" viewBox="0 0 41 60" fill="none"><path d="M41 20.6897C41 32.1162 21.1833 60 20.5 60C19.8167 60 0 32.1162 0 20.6897C0 9.26307 9.17816 0 20.5 0C31.8218 0 41 9.26307 41 20.6897Z" fill="%234661ED"/><ellipse cx="20.5" cy="19.3103" rx="12.3" ry="12.4138" fill="white"/><path d="M18.2676 14.4965V16.0565H19.9476V22.8965H21.8916V14.4965H18.2676Z" fill="%23212121"/></svg>',
      scaledSize: new window.google.maps.Size(41, 60), // Adjust size if needed
    }
    : null;

  const handleMouseOut = () => {
    setHoveredMarker(null);
    setHoveredMarkerPosition(null);
  };

  useEffect(() => {
    if (map && Array.isArray(hits) && hits.length > 0) {
      const bounds = typeof window !== 'undefined' && new window.google.maps.LatLngBounds();
      hits.forEach(hit =>
        bounds.extend(
          typeof window !== 'undefined' &&
          new window.google.maps.LatLng(hit._geoloc.lat, hit._geoloc.lng)
        )
      );
      if (map) {
        map.fitBounds(bounds);
      }
    }
  }, [hits, map]);

  // Toggle Map Visibility
  const handleShowHide = () => {
    setState(prev => ({ ...prev, showMap: !prev.showMap }));
  };

  // Function to create URL to listing
  const createURLToListing = listing => `/l/${listing.objectID}`;

  // Handle listing card click
  const onListingInfoCardClicked = listing => {
    const { title, categoriesLabel, subCategoriesLabel, region, objectID } = listing || {};
    const slug = createSlug(title);
    const params = {
      id: objectID,
      slug,
      city:
        listing.location && listing.location.address
          ? createSlug(listing.location.address.split(',')[0])
          : null,
      category: categoriesLabel ? createSlug(categoriesLabel[0]) : null,
      subcategory: subCategoriesLabel ? createSlug(subCategoriesLabel[0]) : null,
    };

    history.push(
      createResourceLocatorString(
        params.id && params.city && params.category && params.subcategory
          ? 'ListingCityPage'
          : 'ListingPage',
        routeConfiguration(),
        params
      )
    );
  };

  const handleCurrentLocation = () => {
    if (typeof window !== "undefined" && window && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          const newCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.setCenter(newCenter);
        },
        () => alert("Location access denied!")
      );
    }
  };

  return (
    <div className={css.mapRightBar}>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={mapCenter}
          zoom={10}
          onLoad={onLoad}
          onUnmount={onUnMount}
          options={{
            disableDefaultUI: true,
            styles: mapStylesBright,
            zoomControl: false, // Hide default zoom controls
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
          }}
          onDragEnd={() => { }} // Triggers a search when map is moved
        >
          {Array.isArray(hits) &&
            hits.map(hit => {
              const isHovered = hoveredMarker === hit.objectID;
              return (
                <>
                  <Marker
                    key={hit.objectID}
                    position={{ lat: hit._geoloc.lat, lng: hit._geoloc.lng }}
                    onMouseOver={() => handleMouseOver(hit.objectID, hit._geoloc.lat, hit._geoloc.lng)}
                    onMouseOut={handleMouseOut}
                    onClick={() => onListingInfoCardClicked(hit)}
                    icon={customMarkerIcon}
                  />
                  <div className={css.customMapControls}>
                    {/* Current Location Button */}
                    <button className={css.locationButton} onClick={handleCurrentLocation}>
                      <IconMapMarker brand={"defaultLocation"} />
                    </button>
                    {/* Zoom Controls */}
                    <div className={css.zoomControls}>
                      <button onClick={() => map.setZoom(map.getZoom() + 1)}>+</button>
                      <button onClick={() => map.setZoom(map.getZoom() - 1)}>-</button>
                    </div>
                  </div>
                  {isHovered && hoveredMarkerPosition && (
                    <div
                      style={{
                        position: 'absolute',
                        top: `${hoveredMarkerPosition.y - 60}px`,
                        left: `${hoveredMarkerPosition.x}px`,
                        transform: 'translate(-50%, -100%)', // Center the card above the marker
                        zIndex: 1000,
                      }}
                      className={css.hoverCardNew}
                    >
                      <SearchMapInfoCard
                        key={hit.objectID}
                        listings={[hit]}
                        createURLToListing={createURLToListing}
                        onListingInfoCardClicked={onListingInfoCardClicked}
                        intl={intl}
                      />
                    </div>
                  )}
                </>
              );
            })}
        </GoogleMap>
      ) : null}

      {isMobileLayout ? <div className={css.mapInsideToogleButton}>
        <div className={css.checkboxWrapper} onClick={handleShowHide}>
          <div className={`${css.ball} ${!showMap ? css.toggled : ""}`}></div>
        </div>
      </div> : null}
    </div>
  );
};

export default AlgoliaMapUpdate;

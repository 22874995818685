import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import config from '../../config';
import { withViewport } from '../../util/contextHelpers';

import { Footer, Page } from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import MainSearchContent from './MainSearchContent/MainSearchContent';

import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { addOrRemoveToConnected, fetchCurrentCategories } from '../../ducks/user.duck';
import { searchMapListings, setActiveListing } from './AlgoliaSearchPage.duck';

import css from './AlgoliaSearchPage.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 1023;

const AlgoliaSearchPageUpdate = props => {
  const { scrollingDisabled, tab = 'listings', viewport } = props;
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const [state, setState] = useState({
    isSearchMapOpenOnMobile: tab === 'map',
    isMobileModalOpen: false,
    showMap: false,
    subCatToggle: false,
    searchInURL: '',
    dropDownOpen: false,
    totalCount: 0,
    rangeValue: [config.rangeValue],
    toggle: false,
    urlUpdated: false,
  });

  const topbarClasses = state.isMobileModalOpen
    ? classNames(css.topbarBehindModal, css.topbar)
    : css.topbar;

  // const { title, description, schema } = createSearchResultSchema(listings, address, intl);
  // const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      description={'Some Description'}
      title={'Search Page'}
      className={css.mainWrapper}
    >
      <TopbarContainer className={topbarClasses} currentPage="AlgoliaSearchPage" {...props} />
      <MainSearchContent {...props} state={state} setState={setState} isMobileLayout={isMobileLayout} />
      <Footer />
    </Page>
  );
};
const mapStateToProps = state => {
  const {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    categories,
    subCategories,
    subChildCategories,
    categoriesInProgress,
  } = state.user;

  return {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    categories,
    subCategories,
    categoriesInProgress,
    subChildCategories,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onFetchCurrentCategories: payload => dispatch(fetchCurrentCategories(payload)),
  onAddOrRemoveToConnected: (listingId, isAddedPreferred, businessName) =>
    dispatch(addOrRemoveToConnected(listingId, isAddedPreferred, businessName)),
});
const AlgoliaSearchPageComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl,
  withRouter
)(AlgoliaSearchPageUpdate);

export default AlgoliaSearchPageComponent;

import React, { useCallback } from 'react';
import algoliasearch from 'algoliasearch';
import classNames from 'classnames';
import {
  Configure,
  InfiniteHits,
  InstantSearch,
  useHits,
} from 'react-instantsearch';

import { useQuery } from '../../../util/contextHelpers';

import { ListingCard } from '../../../components';

import AlgoliaMapUpdate from '../AlgoliaMapUpdate';
import FilterFacets from './FilterFacets';

import css from '../AlgoliaSearchPage.module.css';
import InfiniteSearch from './InfiniteSearch';

const indexName = process.env.REACT_APP_ALGOLIA_LISTING_INDEX.toString();
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
  // 'latency',
  // '6be0576ff61c053d5f9a3225e2a90f76',
  // {
  //   _useRequestCache: true,
  // }
);

// Have 2 search criteria
// 1. SearchParams which contains => ?keywords
// 2. Routes which contains /:region/:city/:categoriesLabel/:subCategoriesLabel

// Chaning the whole search system using
// Facets to display the category,subCategory and city & region and keyword search only using map view and listing view for futher division

const MainSearchContent = props => {
  const {
    params,
    state,
    setState,
    location,
    history,
  } = props;

  const updateURL = ({ updates = {} }) => {
    const {
      routes: {
        categoriesLabel = null,
        subCategoriesLabel = null,
        region = null,
        city = null,
      } = {},
      query: { filters = {} } = {},
    } = updates || {};

    // Construct query string
    const queryString =
      filters?.keywords != '' && Object.keys(filters).length
        ? `?${new URLSearchParams(filters).toString()}`
        : '';

    // Construct the updated route path
    const updatedPath = `/search/${region || params.region}/${city ||
      params.city}/${categoriesLabel || params.categoriesLabel}/${subCategoriesLabel ||
      params.subCategoriesLabel}${queryString}`;

    history.push(updatedPath);
  };
  const searchParams = Object.fromEntries(useQuery(location.search).entries());

  Object.assign(params, { ...searchParams });

  const parseQueryString = useCallback(
    params => {
      let result = [];

      Object.keys(params).forEach(key => {
        if (!['keywords'].includes(key) && params[key] != 'all') {
          // If key has multiple values (array), format it correctly for Algolia
          if (Array.isArray(params[key])) {
            const filterValues = params[key].map(value => `${key}:"${value}"`).join(' OR ');
            result.push(`(${filterValues})`);
          } else {
            result.push(`${key}:"${params[key]}"`);
          }
        }
      });
      return {
        filters: `${result?.length ? result.join(' AND ') + ' AND ' : ''
          }state:"published" AND (ListingType:"VENDOR_PROFILE" OR ListingType:"VENUE_PROFILE")`,
      };
    },
    [params]
  );

  return (
    <div className={classNames(css.container, state.showMap ? css.fullContainer : '')}>
      <div>
        <InstantSearch
          key={`${location.pathname}-${location.key}`}
          searchClient={searchClient}
          indexName={indexName}
          insights={true}
          initialUiState={{
            [indexName]: {
              query: params.keywords,
              configure: {
                ...parseQueryString(params),
              },
            },
          }}
        >
          <div className={css.mainContainer}>
            <div className={css.leftPanel}>
              <Configure hitsPerPage={16} />
              <div className={css.topFiltercontainer}>
                <div className={css.filterBox}>
                  <FilterFacets {...props} updateURL={updateURL} showMap={state.showMap} subCatToggle={state.subCatToggle} setShowMap={setState} />
                </div>
              </div>
              <div>
                <div className={classNames(css.cardsGrid, state.showMap ? css.fullCardGrid : '')}>
                  <InfiniteSearch {...props}  />
                </div>
              </div>
            </div>
            {/* Map */}
            {state.showMap ? (
              <div className={css.mapContainer}>
                <AlgoliaMapUpdate {...props} />
              </div>
            ) : null}
          </div>
        </InstantSearch>
      </div>
    </div>
  );
};

export default MainSearchContent;

import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { SearchBox, useHits, useMenu } from 'react-instantsearch';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';

import { stringFromLength } from '../../../util/data';
import { SampleNextArrow, SamplePrevArrow } from '../../../util/sagaHelpers';
import { truncateText } from '../../../util/urlHelpers';

import {
  Button,
  IconCollection,
  IconProfileSetup,
  NamedLink,
  ResponsiveImage,
  SkeletonLoader
} from '../../../components';

import css from '../AlgoliaSearchPage.module.css';

// const indexName = process.env.REACT_APP_ALGOLIA_LISTING_INDEX.toString();

export function MenuSelect(props) {
  const {
    params,
    updateURL,
    label,
    attributeKey,
    intl,
    ...rest
  } = props;

  const { items, refine } = useMenu(rest);
  // const { value: selectedValue } = items.find(item => item.isRefined) || { value: '' };
  const { region = null, city = null } = params || {};
  const allRegionsText = intl.formatMessage({ id: 'FilterFacets.AllRegionsText' });
  const allCitiesText = intl.formatMessage({ id: 'FilterFacets.AllCitiesText' });
  const definedValue =
    attributeKey == 'region'
      ? region == 'all'
        ? label
        : region
      : attributeKey == 'city'
        ? city == 'all'
          ? label
          : city
        : label;
  const definedLabel =
    attributeKey == 'region' ? allRegionsText : attributeKey == 'city' ? allCitiesText : label;

  return (
    <select
      value={definedValue}
      onChange={event => {
        const vl = event.target.value;
        // vl !== 'all' ? refine(vl) : refine();
        vl !== 'all' ? refine(vl) : null;
        if (vl == 'all') {
          Object.assign(params, { [props.attribute]: 'all' });
          updateURL({
            updates: { routes: { [props.attribute]: 'all' } },
          });
        } else {
          Object.assign(params, { [props.attribute]: vl });
          updateURL({
            updates: { routes: { [props.attribute]: vl } },
          });
        }
      }}
    >
      <option placeholder={'Region'} value={'all'}>
        {/* {label} */}
        {definedLabel}
      </option>
      {items.map(item => (
        <option key={item.value} value={item.value}>
          {item.label} ({item.count})
        </option>
      ))}
    </select>
  );
}

const settings = {
  className: 'center',
  infinite: true,
  speed: 500,
  rows: 2,
  slidesPerRow: 4,
  arrows: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  // variableWidth: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesPerRow: 1,
      },
    },
  ],
};

const SubCategoryFilters = props => {
  const { params, updateURL, subCategories = [], state, subCatToggle, intl, categories = [] } = props;
  // const { refine, items } = useMenu({ attribute: 'subCategoriesLabel' });
  // const filteredSubCategories = subCategories.filter(item =>
  //   items.map(t => t.label).includes(item.label)
  // );

  return (
    <div
      className={classNames(css.heroCategoriesBox, {
        [css.fullHeroCategoriesBox]: !state.showMap,
      })}
    >
      {subCatToggle
        ?
        <div className={css.subCatList}>
          <NamedLink
            name={'AlgoliaSearchPageUpdate'}
            className={classNames(css.pillsSubCat, {
              [css.pillsActive]:
                params?.subCategoriesLabel?.toLowerCase() == 'all',
            })}
            params={{
              region: params.region,
              city: params.city,
              categoriesLabel: params.categoriesLabel,
              subCategoriesLabel: 'all',
            }}
          >
            <div className={css.categoriesText}>
              <h4>{intl.formatMessage({ id: "FilterFacets.AllSubCatText" })}</h4>
            </div>
          </NamedLink>
          {subCategories && subCategories.length
            ? subCategories
              .sort((a, b) => ('' + a.label).localeCompare(b.label))
              .map((item, index) => {
                const isSelected = item?.key?.toLowerCase() === params?.subCategoriesLabel?.toLowerCase();
                const parentIdLabel = Array.isArray(categories) && categories.length ? categories.find(cat => cat.id == item?.parentId)?.label : "all";

                return !subCatToggle ? (
                  <div
                    className={classNames(css.category, {
                      [css.active]: isSelected,
                    })}
                    key={item?.key}
                  >
                    <NamedLink
                      name={'AlgoliaSearchPageUpdate'}
                      key={item?.key}
                      className={css.heroCategories}
                      params={{
                        region: params.region,
                        city: params.city,
                        // categoriesLabel: params.categoriesLabel || parentIdLabel,
                        categoriesLabel: parentIdLabel,
                        subCategoriesLabel: isSelected ? 'all' : item?.key,
                      }}
                    >
                      {item?.image ? (
                        <div className={css.categoriesImage}>
                          <ResponsiveImage
                            url={item?.image}
                            className={css.logoBrand}
                            alt={item?.label}
                          />
                        </div>
                      ) : (
                        <div className={css.noImage}>
                          <FormattedMessage id="SectionHero.noImageText" />
                        </div>
                      )}
                      <div className={css.categoriesText}>
                        <h4>{item?.label}</h4>
                        <p>
                          {item?.shortDescription1
                            ? stringFromLength(item.shortDescription1, 100, true)
                            : ''}
                        </p>
                      </div>
                    </NamedLink>
                  </div>
                ) : (
                  <NamedLink
                    name={'AlgoliaSearchPageUpdate'}
                    key={item?.key}
                    className={classNames(css.pillsSubCat, {
                      [css.pillsActive]: isSelected,
                    })}
                    params={{
                      region: params.region,
                      city: params.city,
                      // categoriesLabel: params.categoriesLabel,
                      categoriesLabel: parentIdLabel,
                      subCategoriesLabel: isSelected ? 'all' : item?.key,
                    }}
                  >
                    <div className={css.categoriesText}>
                      <h4>{item?.label}</h4>
                    </div>
                  </NamedLink>
                );
              })
            : null}
        </div>
        : <Slider {...settings}>
          {subCategories && subCategories.length
            ? subCategories
              .sort((a, b) => ('' + a.label).localeCompare(b.label))
              .map((item, index) => {
                const isSelected = item?.key?.toLowerCase() === params?.subCategoriesLabel?.toLowerCase();
                const parentIdLabel = (Array.isArray(categories) && categories.length && item?.parentId != null) ? categories.find(cat => cat.id == item?.parentId)?.label : "all";

                return !subCatToggle ? (
                  <div
                    className={classNames(css.category, {
                      [css.active]: isSelected,
                    })}
                    key={item?.key}
                  >
                    <NamedLink
                      name={'AlgoliaSearchPageUpdate'}
                      key={item?.key}
                      className={css.heroCategories}
                      params={{
                        region: params.region,
                        city: params.city,
                        // categoriesLabel: params.categoriesLabel || parentIdLabel,
                        categoriesLabel: parentIdLabel,
                        subCategoriesLabel: isSelected ? 'all' : item?.key,
                      }}
                    >
                      {item?.image ? (
                        <div className={css.categoriesImage}>
                          <ResponsiveImage
                            url={item?.image}
                            className={css.logoBrand}
                            alt={item?.label}
                          />
                        </div>
                      ) : (
                        <div className={css.noImage}>
                          <FormattedMessage id="SectionHero.noImageText" />
                        </div>
                      )}
                      <div className={css.categoriesText}>
                        <h4>{item?.label}</h4>
                        <p>
                          {item?.shortDescription1
                            ? stringFromLength(item.shortDescription1, 100, true)
                            : ''}
                        </p>
                      </div>
                    </NamedLink>
                  </div>
                ) : (
                  <NamedLink
                    name={'AlgoliaSearchPageUpdate'}
                    key={item?.key}
                    className={classNames(css.pillsSubCat, {
                      [css.pillsActive]: isSelected,
                    })}
                    params={{
                      region: params.region,
                      city: params.city,
                      categoriesLabel: params.categoriesLabel,
                      subCategoriesLabel: isSelected ? 'all' : item?.key,
                    }}
                  >
                    <div className={css.categoriesText}>
                      <h4>{item?.label}</h4>
                    </div>
                  </NamedLink>
                );
              })
            : null}
        </Slider>
      }
    </div>
  );
};

const CategoryFilters = props => {
  const { params, updateURL, categories, onFetchCurrentCategories, intl } = props;

  const { refine } = useMenu({ attribute: 'categoriesLabel' });
  // const [showAll, setShowAll] = useState(false);
  // const displayedItems = showAll ? categories : categories.slice(0, 6);

  const allText = intl.formatMessage({ id: "FilterFacets.allText" });

  return (
    <div className={css.categoriesList}>
      <div
        className={classNames(css.categoryItem, {
          [css.selected]: params.categoriesLabel == 'all',
        })}
        onClick={() => {
          Object.assign(params, { categoriesLabel: 'all', subCategoriesLabel: 'all' });
          // Updating URL
          updateURL({
            updates: { routes: { categoriesLabel: 'all' } },
          });
        }}
      >
        {allText}
      </div>

      {categories && categories.length
        ? categories.sort((a, b) => a.value.localeCompare(b.value))
          .map((item, index) => {
            const isSelected = item.label == params.categoriesLabel;

            return (
              <div
                key={index}
                className={classNames(css.categoryItem, {
                  [css.selected]: isSelected,
                })}
                onClick={() => {
                  refine(item.value);
                  Object.assign(params, { categoriesLabel: item.value, subCategoriesLabel: 'all' });
                  // Updating URL
                  updateURL({ updates: { routes: { categoriesLabel: item.label } } });
                  onFetchCurrentCategories({
                    type: 'subCategories',
                    parentIds: [item.id],
                    clearPrev: true,
                  });
                }}
              >
                {item.label}
              </div>
            );
          })
        : null}

      {/* {categories.length > 6 && (
        <div
          className={css.categoryItem}
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? 'Less...' : 'More...'}
        </div>
      )} */}
    </div>
  );
};

const RegionAndSearchBoxCarousel = props => {
  // Need to add region & search Here
  const { categories, subCategories, params, viewport, updateURL, intl, showMap } = props;
  const selectedCategory = categories.find(item => item.label == params.categoriesLabel);

  const isMobile = viewport.width < 920;

  const [keywords, setKeywords] = useState(params.keywords || '');

  const debouncedUpdateURL = useCallback(
    debounce(value => {
      updateURL({
        updates: {
          query: { filters: { keywords: value } },
        },
      });
    }, 1000),
    []
  );

  const handleChange = e => {
    const value = e.target.value;
    setKeywords(value);
    debouncedUpdateURL(value);
  };

  const allCategoriesLabelTitle = intl.formatMessage({ id: "FilterFacets.AllCategoriesLabelTitle" });
  const allCategoriesDescription = intl.formatMessage({ id: "FilterFacets.AllCategoriesDescription" });

  // const categoriesLength = (Array.isArray(categories) && categories.length) || 0;
  // const randomCatNumber = Math.floor(Math.random() * categoriesLength);

  return (
    <div>
      <div className={css.bannerBox}>
        <ResponsiveImage
          alt="Event Image"
          url={selectedCategory?.image ?? categories?.[0]?.image}
          // variants={["scaled-small"]}
          className={css.bannerImage}
        />
        <div className={classNames(css.bannerContent, {
          [css.showBlockSearchBar]: showMap
        })}>
          <div className={css.bannerText}>
            <h3 className={css.bannerTitle}>{selectedCategory?.label || allCategoriesLabelTitle}</h3>
            <p className={css.bannerDescription}>
              {truncateText(selectedCategory?.shortDescription1, 100) || allCategoriesDescription}
            </p>
          </div>
          <div className={css.mainCover}>
            <div className={css.searchBar}>
              <div className={css.searchLeft}>
                <div className={css.locationDropDown}>
                  <MenuSelect
                    attributeKey={'region'}
                    label={'Region'}
                    params={params}
                    updateURL={updateURL}
                    intl={intl}
                    limit={30}
                    attribute={'region'}
                    className={css.searchForm}
                  />
                </div>

                <div className={css.searchWrapper}>
                  <div className={css.keyWordBox}>
                    <span className={css.searchIcon}>
                      <IconProfileSetup type="search" />
                    </span>
                    <input
                      className={css.searchKeyword}
                      placeholder="Keywords"
                      value={keywords}
                      autoFocus={Object.keys(params).includes('keywords')}
                      onChange={handleChange}
                    />
                    <div style={{ display: 'none' }}>
                      <SearchBox />
                    </div>
                  </div>
                </div>
              </div>

              <Button
                className={isMobile ? css.goFullButton : css.goButton}
                type="button"
                onClick={e => {
                  if (params.keywords == "" || params.keywords == "undefined")
                    updateURL({
                      updates: {
                        query: { filters: { keywords: params.keywords || "" } },
                      },
                    });
                }}
              >
                <FormattedMessage
                  id={isMobile ? 'SectionHero.findVendersText' : 'SectionHero.goText'}
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HideSelection = ({ isHidden, toggleHide, intl }) => {
  const hideSelectionText = intl.formatMessage({ id: "FilterFacets.hideSelectionText" });
  const showSelectionText = intl.formatMessage({ id: "FilterFacets.showSelectionText" });

  return (
    <div className={css.hideSelection}>
      {isHidden ? showSelectionText : hideSelectionText}
      <span onClick={toggleHide}>
        {' '}
        <IconCollection name={isHidden ? 'ICON_HIDE' : 'ICON_SHOW'} />
      </span>
    </div>
  );
};

const MapSelection = ({ showMap, toggleMap }) => {

  return (
    <div className={css.mapSelection}>
      {showMap ? 'Hide Map' : 'Show Map'}
      <span onClick={toggleMap}>
        {' '}
        <IconCollection name={showMap ? 'ICON_SHOW' : 'ICON_HIDE'} />
      </span>
    </div>
  );
};

const CityFilterAndToggleButtons = props => {
  const { params, updateURL, setState, showMap, intl, subCatToggle, totalResults = 0 } = props || {};

  const toggleHide = () => setState({ subCatToggle: !subCatToggle });
  // const toggleMap = () => setIsMapVisible(!isMapVisible);
  const toggleMap = () => setState({ showMap: !showMap, subCatToggle: true });

  return (
    <>
      {totalResults > 0 ? <div className={css.searchResultsTitle2}>
        <div className={css.searchFilterContainer}>
          <div className={css.searchFilterSelect}>
            <div>
              <MenuSelect
                attributeKey={'city'}
                label={'City'}
                intl={intl}
                params={params}
                updateURL={updateURL}
                limit={30}
                attribute={'city'}
                className={css.searchForm}
              />
            </div>

            {/* Removing sort by as per client ask */}
            {/* <SortBy
            defaultrefinement={indexName}
            items={[
              { label: 'Relevance', value: indexName },
              { label: 'A-Z', value: `${indexName}_alphabet_sorting` },
              { label: 'Recently Joined', value: `${indexName}_recently_joined` },
            ]}
          /> */}

            <div className={css.clearFilters}>
              <div
                onClick={() => {
                  Object.assign(params, {
                    categoriesLabel: 'all',
                    city: 'all',
                    region: 'all',
                    subCategoriesLabel: 'all',
                  });
                  updateURL({
                    updates: {
                      routes: {
                        categoriesLabel: 'all',
                        city: 'all',
                        region: 'all',
                        subCategoriesLabel: 'all',
                      },
                    },
                  });
                }}
              >
                Clear Filters
              </div>
            </div>
          </div>
          <div className={css.searchSwitch}>
            <HideSelection isHidden={subCatToggle} toggleHide={toggleHide} intl={intl} />
            <MapSelection showMap={showMap} toggleMap={toggleMap} />
          </div>
        </div>
        <div className={css.searchResultsTitle}></div>
      </div> : <div className={css.searchResultsTitle2}></div>}
    </>
  );
};

const FilterFacets = props => {
  // 1. category
  // 2. region
  // 3. subCategory
  // 4. city
  const { categoriesInProgress, setState } = props;
  const { results } = useHits(props);
  const totalResults = results?.nbHits || 0;

  useEffect(() => {
    if (totalResults == 0) {
      setState({ showMap: false });
    }
  }, [totalResults]);

  return (
    <div>
      <CategoryFilters {...props} />
      <RegionAndSearchBoxCarousel {...props} />
      {categoriesInProgress ? <SkeletonLoader /> : <SubCategoryFilters {...props} />}
      <CityFilterAndToggleButtons {...props} totalResults={totalResults} />
    </div>
  );
};

export default FilterFacets;
